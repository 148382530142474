import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Option, Select as BaseSelect, SelectProps } from "baseui/select";
import debounce from "lodash.debounce";
import React, {
  ChangeEvent,
  HTMLProps,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { useQuery } from "react-query";
import { StyleObject } from "styletron-react";
import {
  ChevronDown,
  ChevronDownLeft,
  ListSearch,
  X,
} from "tabler-icons-react";

import {
  BASIC_AUTH,
  GAMING_PLATFORMS,
  PEGI_AGE_RATINGS,
  PEGI_CONTENT_DESCRIPTORS,
  TAG_KINDS,
  TAGGABLE_TYPES,
  USER_ROLES,
} from "../constants";
import { ArticleCategory } from "../containers/ArticleCategories/article-categories";
import { Article } from "../containers/Articles/articles";
import { Editor } from "../containers/Editors/editors";
import { Game } from "../containers/Games/games";
import { Label } from "../containers/Labels/labels";
import { Tag } from "../containers/Tags/tags";

type Props = {
  $style?: StyleObject;
  objectId?: number;
  dropdownLabel?: string;
} & SelectProps;

const Select = ({
  $style,
  dropdownLabel,
  ...rest
}: Props): React.ReactElement => {
  const [css, theme] = useStyletron();
  const Component = BaseSelect;

  return (
    <Component
      noResultsMsg={
        <div
          key="error"
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <ListSearch
            color="#999"
            size={18}
            className={css({ marginRight: "5px" })}
          />
          {dropdownLabel || "Brak rekordów"}
        </div>
      }
      overrides={{
        Root: {
          style: {
            ...$style,
          },
        },
        ControlContainer: {
          style: {
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            transitionDuration: "100ms",
          },
        },
        OptionContent: {
          style: ({ $selected }) => ({
            fontWeight: $selected ? 600 : 400,
          }),
        },
        LoadingIndicator: {
          props: {
            overrides: {
              ActivePath: {
                style: {
                  fill: theme.colors.borderFocus,
                },
              },
              Svg: {
                style: {
                  height: "16px",
                  marginRight: "5px",
                },
              },
            },
          },
        },
        SelectArrow: {
          props: {
            overrides: {
              Svg: (props: HTMLProps<HTMLElement>) => (
                <span
                  className={css({
                    cursor: "pointer",
                    display: "inline",
                    lineHeight: 0,
                  })}
                  {...props}
                >
                  <ChevronDown color={theme.colors.borderFocus} size={18} />
                </span>
              ),
            },
          },
        },
        ClearIcon: {
          props: {
            overrides: {
              Svg: (props: HTMLProps<HTMLElement>) => (
                <span
                  className={css({
                    marginRight: "5px",
                    cursor: "pointer",
                    display: "inline",
                    lineHeight: 0,
                  })}
                  {...props}
                >
                  <X color={theme.colors.borderFocus} size={18} />
                </span>
              ),
            },
          },
        },
        Dropdown: {
          style: {
            boxShadow: "none",
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            backgroundColor: theme.colors.inputFill,
            borderLeftWidth: "2px",
            borderLeftStyle: "solid",
            borderLeftColor: theme.colors.borderFocus,
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.colors.borderFocus,
            borderRightWidth: "2px",
            borderRightStyle: "solid",
            borderRightColor: theme.colors.borderFocus,
            borderTopWidth: "2px",
            borderTopStyle: "solid",
            borderTopColor: theme.colors.borderFocus,
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: 200,
                  boxShadow: "none",
                  marginLeft: "1px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  backgroundColor: "transparent",
                },
              },
              Inner: {
                style: {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        StatefulMenu: {
          props: {
            overrides: {
              EmptyState: {
                style: {
                  color: "#999999",
                  paddingTop: theme.sizing.scale300,
                  paddingBottom: theme.sizing.scale300,
                },
              },
            },
          },
        },
      }}
      {...rest}
    />
  );
};

export default Select;

export function ControlledSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

const getSearchDropdownLabel = (
  isLoading: boolean,
  inputLength: number
): string => {
  if (isLoading) {
    return "Trwa ładowanie";
  } else {
    if (inputLength < 3) {
      return "Wprowadź minimum 2 znaki";
    }
    if (inputLength >= 3) {
      return "Nie znaleziono";
    }
    return "";
  }
};

export function ControlledArticlesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { data, isLoading, refetch } = useQuery(
    ["articles", "search", searchingPhrase],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/articles/paginated`,
        {
          ...BASIC_AUTH,
          params: {
            limit: searchingPhrase.length > 1 ? 100 : 0,
            title: `iLike:%${searchingPhrase}%`,
          },
        }
      );
      return data;
    }
  );

  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );

  useEffect(() => data && refetch(), []);

  const dropdownLabel = getSearchDropdownLabel(
    isLoading,
    searchingPhrase.length
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={data?.results.map((item: Article) => ({
            id: item?.id,
            label: item?.title,
          }))}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onInputChange={(event) =>
            debouncedSetSearchingPhrase(event?.currentTarget?.value)
          }
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          dropdownLabel={dropdownLabel}
          {...rest}
        />
      )}
    />
  );
}

type ControlledArticlesCategoriesSelectProps = {
  disable?: "children" | "parent";
};

export function ControlledArticlesCategoriesSelect({
  control,
  name,
  rules,
  disable = "parent",
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> &
  Props &
  ControlledArticlesCategoriesSelectProps): React.ReactElement {
  const { data, isLoading, refetch } = useQuery(
    "articleCategoriesShow",
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/article-categories/paginated`,
        {
          ...BASIC_AUTH,
          params: {
            limit: 100,
          },
        }
      );
      return data;
    }
  );

  useEffect(() => data && refetch(), []);

  const nestChildrenUnits = (
    category: ArticleCategory,
    depth: number
  ): Array<Option> => {
    return data?.results?.filter(
      (aC: ArticleCategory) => aC.parentId === category?.id
    )?.length > 0
      ? (data?.results as ArticleCategory[])
          ?.filter((aC: ArticleCategory) => aC.parentId === category?.id)
          .map((item: ArticleCategory) => [
            {
              id: item?.id,
              label: item.name,
              depth: depth,
              disabled: disable === "children",
            },
          ])
          .flat()
      : [];
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={(data?.results as ArticleCategory[])
            ?.filter((aC) => !aC.parentId)
            .map((item: ArticleCategory) => [
              {
                id: item?.id,
                label: item?.name,
                depth: 0,
                disabled:
                  disable === "parent" &&
                  data?.results?.some(
                    (aC: ArticleCategory) => aC.parentId === item?.id
                  ),
              },
              ...nestChildrenUnits(item, 1),
            ])
            .flat()}
          getOptionLabel={({ option }) => (
            <Block
              marginLeft={option?.depth && `${(option?.depth - 1) * 24}px`}
              display="flex"
              alignItems="center"
              color={option?.disabled ? "#777" : "#000"}
            >
              {option?.depth > 0 && <ChevronDownLeft size={16} />}
              <Block marginLeft={option?.depth && `8px`}>{option?.label}</Block>
            </Block>
          )}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          {...rest}
        />
      )}
    />
  );
}

export function ControlledArticleStatusSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={[
            { id: "Draft", label: "Szkic" },
            { id: "Published", label: "Opublikowany" },
            { id: "Pending", label: "Do weryfikacji" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledMagazineStatusSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={[
            { id: "Draft", label: "Szkic" },
            { id: "Published", label: "Opublikowany" },
            { id: "Pending", label: "Do weryfikacji" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledEditorsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { data, isLoading, refetch } = useQuery(
    ["editors", "search", searchingPhrase],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/editors/paginated`,
        {
          ...BASIC_AUTH,
          params: {
            limit: searchingPhrase.length > 1 ? 100 : 0,
            nick: `iLike:%${searchingPhrase}%`,
          },
        }
      );
      return data;
    }
  );

  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );

  useEffect(() => data && refetch(), []);

  const dropdownLabel = getSearchDropdownLabel(
    isLoading,
    searchingPhrase.length
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={data?.results.map((item: Editor) => ({
            id: item?.id,
            label: item?.nick,
          }))}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onInputChange={(event) =>
            debouncedSetSearchingPhrase(event?.currentTarget?.value)
          }
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          dropdownLabel={dropdownLabel}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledGamesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { data, isLoading, refetch } = useQuery(
    ["games", "search", searchingPhrase],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/games/paginated`,
        {
          ...BASIC_AUTH,
          params: {
            limit: searchingPhrase.length > 1 ? 100 : 0,
            originalTitle: `iLike:%${searchingPhrase}%`,
          },
        }
      );
      return data;
    }
  );

  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );

  useEffect(() => data && refetch(), []);

  const dropdownLabel = getSearchDropdownLabel(
    isLoading,
    searchingPhrase.length
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={data?.results.map((item: Game) => ({
            id: item?.id,
            label: item?.originalTitle,
          }))}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onInputChange={(event) =>
            debouncedSetSearchingPhrase(event?.currentTarget?.value)
          }
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          dropdownLabel={dropdownLabel}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledGamingPlatformsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={GAMING_PLATFORMS}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledLabelsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [css] = useStyletron();

  const { data, isLoading, refetch } = useQuery("labelsList", async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/labels`,
      BASIC_AUTH
    );
    return data;
  });

  useEffect(() => data && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={data?.map((item: Label) => ({
            label: item?.name,
            ...item,
          }))}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          multi
          getOptionLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              <div
                className={css({
                  width: "14px",
                  height: "14px",
                  backgroundColor: (option as Label).color,
                  marginRight: "6px",
                  borderRadius: "50%",
                })}
              />

              {option?.label}
            </Block>
          )}
          getValueLabel={({ option }) => (
            <Block display="flex" alignItems="center">
              <div
                className={css({
                  width: "14px",
                  height: "14px",
                  backgroundColor: (option as Label).color,
                  marginRight: "6px",
                  borderRadius: "50%",
                })}
              />

              {option?.label}
            </Block>
          )}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledSexSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={[
            {
              id: "Male",
              label: "Mężczyzna",
            },
            {
              id: "Female",
              label: "Kobieta",
            },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledTaggableTypesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={TAGGABLE_TYPES}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledTagsKindSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={TAG_KINDS}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

type TagsSelectProps = {
  kind?: string;
};

export function ControlledTagsSelect({
  control,
  kind,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props & TagsSelectProps): React.ReactElement {
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { data, isLoading, refetch } = useQuery(
    [`tagsKind${kind}`, "search", searchingPhrase],
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/tags`,
        {
          ...BASIC_AUTH,
          params: {
            kind: kind as string,
            limit: searchingPhrase.length > 1 ? 100 : 0,
            name: `iLike:%${searchingPhrase}%`,
          },
        }
      );
      return data;
    }
  );

  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );

  useEffect(() => data && refetch(), []);

  const dropdownLabel = getSearchDropdownLabel(
    isLoading,
    searchingPhrase.length
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={data?.map((item: Tag) => ({
            id: item.id,
            label: item.name,
          }))}
          onBlur={onBlur}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onInputChange={(event) =>
            debouncedSetSearchingPhrase(event?.currentTarget?.value)
          }
          value={value}
          multi
          dropdownLabel={dropdownLabel}
          {...rest}
        />
      )}
    />
  );
}

// export function ControlledGenreTagsSelect({
//   control,
//   name,
//   rules,
//   ...rest
// }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
// UseControllerProps<any> & Props): React.ReactElement {
//   const { data, isLoading, refetch } = useQuery("GenreTagsList", async () => {
//     const { data } = await axios.get(
//       `${process.env.REACT_APP_API_URL}/admin/tags`,
//       {
//         ...BASIC_AUTH,
//         params: {
//           kind: "Genre",
//         },
//       }
//     );
//     return data;
//   });

//   useEffect(() => data && refetch(), []);

//   return (
//     <Controller
//       control={control}
//       name={name}
//       rules={rules}
//       render={({ field: { onChange, onBlur, value } }) => (
//         <Select
//           options={data?.map((item: Tag) => ({
//             id: item.id,
//             label: item.name,
//           }))}
//           onChange={(params) => params && onChange(params.value)}
//           onBlur={onBlur}
//           isLoading={isLoading}
//           value={value}
//           multi
//           {...rest}
//         />
//       )}
//     />
//   );
// }

// export function ControlledMultiplayerTagsSelect({
//   control,
//   name,
//   rules,
//   ...rest
// }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
// UseControllerProps<any> & Props): React.ReactElement {
//   const { data, isLoading, refetch } = useQuery(
//     "multiplayerTagsList",
//     async () => {
//       const { data } = await axios.get(
//         `${process.env.REACT_APP_API_URL}/admin/tags`,
//         {
//           ...BASIC_AUTH,
//           params: {
//             kind: "Multiplayer",
//           },
//         }
//       );
//       return data;
//     }
//   );

//   useEffect(() => data && refetch(), []);

//   return (
//     <Controller
//       control={control}
//       name={name}
//       rules={rules}
//       render={({ field: { onChange, onBlur, value } }) => (
//         <Select
//           options={data?.map((item: Tag) => ({
//             id: item.id,
//             label: item.name,
//           }))}
//           onChange={(params) => params && onChange(params.value)}
//           onBlur={onBlur}
//           isLoading={isLoading}
//           value={value}
//           multi
//           {...rest}
//         />
//       )}
//     />
//   );
// }

// export function ControlledTagsSelect({
//   control,
//   name,
//   rules,
//   ...rest
// }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
// UseControllerProps<any> & Props): React.ReactElement {
//   const { data, isLoading, refetch } = useQuery("tagsList", async () => {
//     const { data } = await axios.get(
//       `${process.env.REACT_APP_API_URL}/admin/tags`,
//       BASIC_AUTH
//     );
//     return data;
//   });

//   useEffect(() => data && refetch(), []);

//   return (
//     <Controller
//       control={control}
//       name={name}
//       rules={rules}
//       render={({ field: { onChange, onBlur, value } }) => (
//         <Select
//           options={data?.map((item: Tag) => ({
//             id: item.id,
//             label: item.name,
//           }))}
//           onChange={(params) => params && onChange(params.value)}
//           onBlur={onBlur}
//           isLoading={isLoading}
//           value={value}
//           multi
//           {...rest}
//         />
//       )}
//     />
//   );
// }

export function ControlledVideoTypesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={[
            { id: "Facebook", label: "Facebook" },
            { id: "Iframe", label: "Iframe" },
            { id: "Twitch", label: "Twitch" },
            { id: "YouTube", label: "YouTube" },
          ]}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledPegiAgeRatingSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={PEGI_AGE_RATINGS}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={false}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledPegiContentDescriptorsMultiSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={PEGI_CONTENT_DESCRIPTORS}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          multi
          clearable={true}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledUserRolesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={USER_ROLES}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          clearable={true}
          {...rest}
        />
      )}
    />
  );
}

export function ControlledSectionsSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  const [css] = useStyletron();

  const { data, isLoading, refetch } = useQuery("sections", async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/sections`,
      BASIC_AUTH
    );
    return data;
  });

  useEffect(() => data && refetch(), []);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Select
          options={data?.results?.map((item: Label) => ({
            label: item?.name,
            ...item,
          }))}
          isLoading={isLoading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          maxDropdownHeight="300px"
          multi
          {...rest}
        />
      )}
    />
  );
}
