import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { Spinner } from "baseui/spinner";
import debounce from "lodash.debounce";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { AlertCircle, Ban, Plus, Star } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import Pagination from "../../../components/pagination";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import Thumbnail from "../../../components/thumbnail";
import { BASIC_AUTH, PAGE_SIZE } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { Video } from "../videos";

enum FieldName {
  Title = "title",
  PublishedAt = "publishedAt",
}

export default function VideosIndex(): React.ReactElement {
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const query = new URLSearchParams(useLocation().search);
  const pageNumber = parseInt(query.get("page") as string);
  const [sortBy, setSortBy] = useState<FieldName | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    null
  );
  const [searchingInputValue, setSearchingInputValue] = useState("");
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const { control } = useForm<{ searchingPhrase: string }>();
  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const {
    isError,
    data,
    isLoading: isQueryLoading,
    isFetching: isQueryFetching,
    refetch,
  } = useQuery(
    "videos",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/videos/paginated`,
          {
            ...BASIC_AUTH,
            params: {
              limit: PAGE_SIZE,
              offset: (paginationNumber - 1) * pageSize,
              title: `iLike:%${searchingPhrase}%`,
              sort: `${sortDirection === SortDirection.ASC ? "" : "-"}${
                sortBy || "publishedAt"
              }`,
            },
          }
        )
      ).data
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Title)}
            sortDirection={sortBy === FieldName.Title ? sortDirection : null}
          >
            Tytuł
          </SortingTableHeader>
        ),
        id: "title",
        Cell: ({ row }: { row: any }) => (
          <Thumbnail
            href={`/videos/${row?.original?.id}`}
            imageUrl={row.original.thumbnailUrl}
            label={row?.original?.title}
          />
        ),
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            Na stronie głównej
          </Block>
        ),
        id: "onMainPage",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              {row?.original?.isBlockedFromHomePage ? (
                <Ban
                  size={18}
                  className={css({
                    color: theme.colors.negative,
                  })}
                />
              ) : row?.original?.isFeaturedOnHomePage ? (
                <Star
                  size={18}
                  className={css({
                    color: theme.colors.positive,
                  })}
                />
              ) : (
                <></>
              )}
            </Block>
          );
        },
      },
      {
        Header: (
          <Block display="flex" alignItems="center" justifyContent="center">
            Widoczność
          </Block>
        ),
        id: "visibility",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="center">
              <FormattedValue dataType="visibility-icon" showBlankWhenEmpty>
                {new Date(row?.original?.publishedAt) > new Date()}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.PublishedAt)}
            sortDirection={
              sortBy === FieldName.PublishedAt ? sortDirection : null
            }
            $style={{ justifyContent: "flex-end" }}
          >
            Data publikacji
          </SortingTableHeader>
        ),
        id: "publishedAt",
        Cell: ({ row }: { row: any }) => {
          return (
            <Block display="flex" alignItems="center" justifyContent="flex-end">
              <FormattedValue dataType="datetime" showBlankWhenEmpty>
                {row?.original?.publishedAt}
              </FormattedValue>
            </Block>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/videos/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/videos/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/videos/${row?.original?.id}`}
              $style={{ marginLeft: "10px" }}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/videos/${row?.original?.id}`);
              }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    refetch();
    setIsFetching(true);
  }, [paginationNumber, pageNumber]);

  useEffect(() => {
    refetch();
  }, [sortBy, sortDirection]);

  useEffect(() => {
    refetch();
  }, [searchingPhrase]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
    pageNumber && setPaginationNumber(pageNumber);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  return (
    <article>
      <Header
        title="Wideo"
        recordsNum={data?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj wideo",
            kind: KIND.secondary,
            startEnhancer: <Plus size={18} />,
            onClick: () => history.push("/videos/create"),
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={8} />
          <Cell span={4}>
            <Block marginBottom="30px">
              <FormControl>
                <ControlledInput
                  control={control}
                  value={searchingInputValue}
                  onChange={(event) => {
                    setSearchingInputValue(event?.currentTarget?.value);
                    debouncedSetSearchingPhrase(event?.currentTarget?.value);
                  }}
                  name="searchingPhrase"
                  size={SIZE.compact}
                  placeholder="Wyszukaj"
                  clearable
                  endEnhancer={
                    isQueryFetching && !isQueryLoading && <Spinner size={18} />
                  }
                />
              </FormControl>
            </Block>
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<Video> columns={columns} data={data?.results} noOffset />
              )
            )}
          </Cell>
          <Cell span={12}>
            {data && !!data?.totalCount && data?.totalCount > PAGE_SIZE && (
              <Pagination
                numPages={Math.ceil(data?.totalCount / pageSize)}
                currentPage={paginationNumber}
                onPageChange={setPaginationNumber}
              />
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
