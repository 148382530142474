import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelMedium } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AlertCircle, Check, FileOff, Plus } from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import { ControlledCheckbox } from "../../../components/checkbox";
import Content from "../../../components/content";
import DateTimePicker from "../../../components/date-time-picker";
import { ControlledEditor } from "../../../components/editor";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import ImagePreview, {
  ImagePreviewSize,
} from "../../../components/image-preview";
import { ControlledInput } from "../../../components/input";
import {
  ControlledEditorsSelect,
  ControlledGamesSelect,
  ControlledLabelsSelect,
  ControlledTagsSelect,
  ControlledVideoTypesSelect,
} from "../../../components/select";
import { ControlledTextArea } from "../../../components/text-area";
import { BASIC_AUTH } from "../../../constants";
import { useImageLibrary } from "../../../contexts/image-library-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { formValidation } from "../../../utils/formValidation";
import { slugifyText } from "../../../utils/slugify";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { FormInputs, VIDEOS_FIELDS } from "../videos.form";

export default function VideosCreate(): React.ReactElement {
  const [publishedAtDate, setPublishedAtDate] = useState<Date | null>(null);
  const [isSlugChangedByUser, setIsSlugChangedByUser] = useState(false);
  const [css] = useStyletron();
  const { enqueue } = useSnackbar();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const watchTitle = watch("title");
  const publishedAt = publishedAtDate && publishedAtDate.toISOString();
  const { open } = useImageLibrary();

  const postData = async (values: FormInputs) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/videos`,
      values,
      BASIC_AUTH
    );
    return data;
  };

  const { isError, mutateAsync } = useMutation("videoCreate", postData);

  const onSubmit = async ({
    title,
    slug,
    lead,
    types,
    duration,
    source,
    thumbnailUrl,
    isFeaturedOnHomePage,
    isBlockedFromHomePage,
    tags,
    labels,
    editors,
    games,
    body,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await mutateAsync({
        title,
        slug,
        lead,
        type: types && types[0]?.id,
        duration: formatToSeconds(String(duration)),
        source,
        thumbnailUrl,
        isFeaturedOnHomePage: !!isFeaturedOnHomePage,
        isBlockedFromHomePage: !!isBlockedFromHomePage,
        publishedAt,
        tagIds: tags && tags.map(({ id }: { id: number }) => id),
        labelIds: labels && labels.map(({ id }: { id: number }) => id),
        editorId: editors && editors[0]?.id,
        gameId: games && games[0]?.id,
        body,
      });
      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });
      history.push(`/videos/${data?.id}`);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    setIsSlugChangedByUser(false);
  }, []);

  useEffect(() => {
    watchTitle !== undefined &&
      !isSlugChangedByUser &&
      setValue("slug", slugifyText(watchTitle));
  }, [watchTitle]);

  const formatToSeconds = (duration: string) => {
    const splitedDuration = duration.split(":");
    return (
      Number(splitedDuration[0]) * 3600 +
      Number(splitedDuration[1]) * 60 +
      Number(splitedDuration[2])
    );
  };

  return (
    <article>
      <Header
        title="Nowe wideo"
        labels={["Wideo", "Tworzenie"]}
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            onClick: () => history.push("/videos"),
            disabled: isLoading,
            isLoading: isLoading,
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
          },
        ]}
        onBack={() =>
          history.push(
            history.location.pathname.split("/").slice(0, -1).join("/")
          )
        }
      />
      <Content>
        <form>
          <Grid>
            {VIDEOS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      required={item.create.required}
                      error={
                        (errors as any)[item.id] &&
                        (errors as any)[item.id].message
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.Checkbox ? (
                        <ControlledCheckbox
                          control={control}
                          name={item.id}
                          disabled={isLoading}
                          $style={{ marginTop: "30px" }}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        >
                          <p
                            className={css({
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            })}
                          >
                            {item.label}
                          </p>
                        </ControlledCheckbox>
                      ) : item.type === FIELDS.ImagePicker ? (
                        <>
                          {watch(item.id as keyof FormInputs) && (
                            <ImagePreview
                              image={{
                                path: watch(item.id as keyof FormInputs),
                              }}
                              size={ImagePreviewSize.Large}
                            />
                          )}
                          <Button
                            type="button"
                            size="compact"
                            kind={KIND.secondary}
                            onClick={() =>
                              open((image) =>
                                setValue(
                                  item.id as keyof FormInputs,
                                  `${process.env.REACT_APP_CDN_URL}/images/${image.path}`
                                )
                              )
                            }
                            $style={{ marginTop: "10px" }}
                          >
                            Wybierz z biblioteki lub wgraj
                          </Button>
                        </>
                      ) : item.type === FIELDS.TagsSelect ? (
                        <ControlledTagsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.VideoTypesSelect ? (
                        <ControlledVideoTypesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DateTimePicker ? (
                        <Block display="flex" alignItems="center">
                          <DateTimePicker
                            date={publishedAtDate}
                            setDate={setPublishedAtDate}
                          />

                          <Button
                            kind={KIND.secondary}
                            $style={{
                              marginBottom: "-12px",
                            }}
                            onClick={(event: MouseEvent) => {
                              event.preventDefault();

                              setPublishedAtDate(new Date());
                            }}
                          >
                            Ustaw aktualny czas
                          </Button>
                        </Block>
                      ) : item.type === FIELDS.Editor ? (
                        <ControlledEditor control={control} name={item.id} />
                      ) : item.type === FIELDS.LabelsSelect ? (
                        <ControlledLabelsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.EditorsSelect ? (
                        <ControlledEditorsSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.GamesSelect ? (
                        <ControlledGamesSelect
                          control={control}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          masked={item.mask !== undefined}
                          mask={item.mask}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                          {...(item.id === "slug" && {
                            onKeyUp: () => setIsSlugChangedByUser(true),
                            $style: {
                              color: isSlugChangedByUser ? "#000" : "#999",
                            },
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
          </Grid>
        </form>
      </Content>
    </article>
  );
}
